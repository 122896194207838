/** @format */

import Vue from 'vue'
import vueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(vueCompositionApi)

const state = reactive({
	GetAllVisitableCustomerSearchComponentType: '',
	isAddCustomerInfoModalVisible: false,
	editorContentCustomerConsumedProducts: '',
	editorContentCustomerLog: '',
	// ? ----------------------------------------------------------------------
	isCustomerInfoMarketingModulesVisible: false,
	isGetAllVisitableCustomerSearchComponentVisible: true,
	keyCustomerInfoMarketingModules: 1,
	companyID: '',
	customerID: '',
	customer: '',
	visitableCustomersTableColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
		},
		{
			field: 'customer',
			label: 'Customer',
			sortable: true,
			filterable: true,
		},
		{
			field: 'address',
			label: 'Address',
			sortable: true,
			filterable: true,
		},
		{
			field: 'phone',
			label: 'Phone',
			sortable: true,
			filterable: true,
		},
		{
			field: 'person',
			label: 'Person',
			sortable: true,
			filterable: true,
		},
	],
})

export default function useCustomerInfoState() {
	return {
		...toRefs(state),
	}
}
