<template>
	<div>
		<div>
			<VPopup :closePopup="onClosePopup">
				<template #header>
					<div>
						{{ formName }}
					</div>
				</template>
				<template #body>
					<section>
						<div style="margin-top: 25px; margin-bottom: 15px">
							<span class="title" style="color: red"
								>Customer: {{ customer }}
							</span>
						</div>
					</section>
					<section>
						<div style="margin-top: 25px; margin-bottom: 15px">
							<span class="title">Customer Consumed Products</span>
						</div>
						<textarea
							class="textarea"
							placeholder="Enter customer consumed products"
							rows="10"
							v-model="editorContentCustomerConsumedProducts"
						></textarea>
					</section>
					<section>
						<div>
							<div style="margin-top: 25px; margin-bottom: 15px">
								<span class="title">Customer Logs</span>
							</div>
							<textarea
								class="textarea"
								placeholder="Enter the notes about the customer"
								rows="10"
								v-model="editorContentCustomerLog"
							></textarea>
						</div>
					</section>
					<section>
						<b-button
							class="width-250"
							style="
								background-color: rgb(32, 156, 238);
								color: white;
								margin-top: 35px;
							"
							@click="onSave"
						>
							Save All changes
						</b-button>
					</section>
				</template>
				<template #footer>
					<div>
						{{ formName }}
					</div>
				</template>
			</VPopup>
		</div>
	</div>
</template>

<script>
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import useCustomerInfoState from '@/_srcv2/pages/business-development/customer-info/scripts/useCustomerInfoState.js'
import GetCustomerLogsAndProductsQuery from '@/_srcv2/pages/business-development/customer-info/graphql/GetCustomerLogsAndProductsQuery.graphql'
import AddCustomerInfoMutation from '@/_srcv2/pages/business-development/customer-info/graphql/AddCustomerInfoMutation.graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import { ref, reactive, computed } from '@vue/composition-api'

export default {
	name: 'AddCustomerInfoModal',
	components: {
		VPopup,
	},
	setup() {
		const {
			isAddCustomerInfoModalVisible,
			editorContentCustomerConsumedProducts,
			editorContentCustomerLog,
			companyID,
			customerID,
			customer,
		} = useCustomerInfoState()
		// ? ----------------------------------------------------------------
		const queryVariables = ref({
			company_id: Store.getters.getUserCurrentCompany,
			customer_id: customerID,
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, onError } = useQuery(
			GetCustomerLogsAndProductsQuery,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			console.log('------>>> result', result?.data?.customers)
			const customerInfo = result?.data?.customers[0]
			editorContentCustomerLog.value = customerInfo?.customer_log
			editorContentCustomerConsumedProducts.value =
				customerInfo?.customer_consumed_products
		})
		onError((error) => {
			console.log('error', error)
		})
		// todo -----------------------------------------------------------
		const formName = ref('Add Customer Info such as consumed products & logs')
		const onClosePopup = () => {
			if (
				window.confirm(
					'Are you sure you want to exit without saving the changes you have made? All changes will be lost.',
				)
			) {
				isAddCustomerInfoModalVisible.value = false
			} else {
				return
			}
		}
		// todo ------------------------------------------------
		const mutationInput = computed(() => {
			return {
				company_id: Store.getters.getUserCurrentCompany,
				customer_id: customerID.value,
				customer_consumed_products: editorContentCustomerConsumedProducts.value,
				customer_log: editorContentCustomerLog.value,
			}
		})
		// ? Add new customer visit or alarm
		const { mutate: addInfo, onDone } = useMutation(
			AddCustomerInfoMutation,
			() => ({
				variables: mutationInput.value,
			}),
		)
		onDone((res) => {
			console.log('onDone is fired')
			console.log('res', res.data.update_customers.returning[0])
			alert('Customer Info has been added successfully')
		})
		// todo ------------------------------------------------
		const onSave = () => {
			if (
				window.confirm(
					'Are you sure you want to save the form with the changes you have made? This process is irreversible.',
				)
			) {
				console.log('from modal onSave')
				addInfo()
				isAddCustomerInfoModalVisible.value = false
			} else {
				return
			}
		}
		return {
			onClosePopup,
			formName,
			onSave,
			isAddCustomerInfoModalVisible,
			editorContentCustomerConsumedProducts,
			editorContentCustomerLog,
			companyID,
			customerID,
			customer,
		}
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 1.3rem;
	font-weight: 700;
	color: #0b10a8d9;
}
</style>
