<template>
	<div>
		<div>
			<vue-good-table
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				@on-cell-click="onCellClick"
				:search-options="{
					enabled: true,
				}"
				:sort-options="sortOptions"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">{{ buttonTag }}</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
		</div>
		<div v-if="isAddCustomerInfoModalVisible">
			<AddCustomerInfoModal />
		</div>
	</div>
</template>

<script>
import AddCustomerInfoModal from '@/_srcv2/pages/business-development/customer-info/add-customer-info/components/AddCustomerInfoModal.vue'
import useCustomerInfoState from '@/_srcv2/pages/business-development/customer-info/scripts/useCustomerInfoState.js'
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import GetAllVisitableCustomersAddressListQuery from '@/_srcv2/pages/business-development/daily-visits/graphql/GetAllVisitableCustomersAddressListQuery.graphql'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'

export default {
	name: 'GetAllVisitableCustomerSearchComponent',
	components: {
		AddCustomerInfoModal,
	},
	setup() {
		const {
			isGetAllVisitableCustomerSearchComponentVisible,
			isCustomerInfoMarketingModulesVisible,
			keyCustomerInfoMarketingModules,
			GetAllVisitableCustomerSearchComponentType,
			visitableCustomersTableColumns,
			isAddCustomerInfoModalVisible,
			companyID,
			customerID,
			customer,
		} = useCustomerInfoState()
		const buttonTag = computed(() => {
			if (
				GetAllVisitableCustomerSearchComponentType.value === 'AddCustomerInfo'
			) {
				return 'Update Info'
			} else if (
				GetAllVisitableCustomerSearchComponentType.value === 'ShowMarketingInfo'
			) {
				return 'Get Marketing Info'
			} else if (
				GetAllVisitableCustomerSearchComponentType.value === 'ShowEconomicInfo'
			) {
				return 'Get Economic Info'
			} else {
				return 'Select'
			}
		})
		// todo -----------------------------------------------------------
		const fetchAddresses = (data) => {
			return new Promise((resolve, reject) => {
				const addresses = data.map((item) => {
					return {
						customerId: item.customer.customer_id,
						customer: `${item.customer.customer_id} - ${item.customer.customer_title} - ${item.customer.customer_nickname}`,
						address: `${item.line_1}  ${item.line_2} ${item.post_code} ${item.city}`,
						addressId: item.address_id,
						phone: item.address_phone || '',
						person: item.order_clerk || '',
					}
				})
				if (data !== null || undefined) {
					resolve(addresses)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ? -----------------------------------------------------------------
		const sortOptions = reactive({
			enabled: true,
			multipleColumns: true,
			initialSortBy: [{ field: 'customer', type: 'asc' }],
		})
		onMounted(() => {
			refetch()
			// ? -------------------
			sortOptions.initialSortBy = [{ field: 'customer', type: 'asc' }]
		})
		// ? ----------------------------------------------------------------
		const queryVariables = ref({
			company_id: Store.getters.getUserCurrentCompany,
			customer_is_active: true,
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'cache-first',
		})
		const { refetch, onResult } = useQuery(
			GetAllVisitableCustomersAddressListQuery,
			() => queryVariables.value,
			options,
		)
		const rows = ref([])
		onResult((result) => {
			console.log('result', result.data.addresses)
			fetchAddresses(result.data.addresses).then((data) => (rows.value = data))
		})
		// todo -----------------------------------------------------------
		const onCellClick = (params) => {
			console.log('---->>> onCellClick params', params.row)
			// todo -----------------------------------------------------------
			if (
				GetAllVisitableCustomerSearchComponentType.value === 'AddCustomerInfo'
			) {
				console.log('params.row', params.row)
				isAddCustomerInfoModalVisible.value = true
				companyID.value = Store.getters.gatUserCurrentCompany
				customerID.value = params.row.customerId
				customer.value = params.row.customer
			} else if (
				GetAllVisitableCustomerSearchComponentType.value === 'ShowMarketingInfo'
			) {
				isGetAllVisitableCustomerSearchComponentVisible.value = false
				isCustomerInfoMarketingModulesVisible.value = true
				console.log('ShowMarketingInfo params.row', params.row)
				console.log(
					'ShowMarketingInfo params.row.customerId',
					params.row.customerId,
				)
				companyID.value = Store.getters.gatUserCurrentCompany
				customerID.value = params.row.customerId
				customer.value = params.row.customer
				keyCustomerInfoMarketingModules.value += 1
				// isCustomerInfoMarketingModulesVisible.value = false
				// setTimeout(() => {
				// 	isCustomerInfoMarketingModulesVisible.value = true
				// }, 1000)
			} else if (
				GetAllVisitableCustomerSearchComponentType.value === 'ShowEconomicInfo'
			) {
				return
			} else {
				return
			}
		}
		// todo -----------------------------------------------------------
		// ? -----------------------------------------------------------------
		return {
			refetch,
			rows,
			columns: visitableCustomersTableColumns,
			sortOptions,
			onCellClick,
			buttonTag,
			isAddCustomerInfoModalVisible,
		}
	},
}
</script>

<style lang="scss" scoped></style>
